import React from "react";
import { Link } from "gatsby";
import { Button, ButtonVariant } from "brass-ui-kit";

import * as styles from "./careers.module.scss";
import markdownToHtml from "helpers/utils/markdownLoader";
import SEO from "components/SEO";

type CareerTemplateopening = {
  pageContext: {
    career: {
      slug: string;
      city: string;
      date: string;
      role: string;
      team: string;
      link: string;
      country: string;
      position: string;
      aboutApplicant: string;
      introduction: string;
      responsibilities: string;
      type: ["Hybrid", "Remote", "On-site"];
    };
  };
};

export const CareersTemplate: React.FC<CareerTemplateopening> = ({
  pageContext: { career },
}) => {
  const navs = ["Introduction", "Role", "Responsibilities", "About You"];
  const opening = {
    ...career,
    introduction: markdownToHtml(career.introduction),
    role: markdownToHtml(career.role),
    responsibilities: markdownToHtml(career.responsibilities),
    aboutApplicant: markdownToHtml(career.aboutApplicant),
  };

  return (
    <>
      <SEO
        type="job"
        slug={`/${opening.slug}`}
        description={opening.role}
        datePublished={opening.date}
        city={opening.city}
        country={opening.country}
        title={`${opening.position} - ${opening.team}`}
      />
      <div className={styles.Template_container}>
        <section className={styles.Template_hero}>
          <div>
            <p className={styles.Template_hero_subtitle}>{opening.team} team</p>
            <h2 className={styles.Template_hero_title}>{opening.position}</h2>
            <div className={styles.Template_hero_meta}>
              <div className={styles.Template_hero_meta_location}>
                {/* <Nigeria /> */}
                <p>
                  {opening.city}, {opening.country} | {opening.type}
                </p>
              </div>
              <Link to="/careers#roles">See all open roles</Link>
            </div>
          </div>
        </section>

        <section className={styles.Template_content}>
          <div className={styles.Template_content_left}>
            <div>
              <div className={styles.Template_content_left_headings}>
                {navs.map((item) => (
                  <a
                    href={`#${item.toLowerCase().split(" ").join("-")}`}
                    key={item}
                  >
                    {item}
                  </a>
                ))}
              </div>
              <Button
                as="a"
                target="_blank"
                href={opening.link}
                variant={ButtonVariant.Primary}
              >
                Apply
              </Button>
            </div>
          </div>
          <div className={styles.Template_content_right}>
            <div id="introduction">
              <h3>Introduction</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: opening.introduction,
                }}
              />
            </div>
            <div id="role">
              <h3>Role</h3>
              <div dangerouslySetInnerHTML={{ __html: opening.role }} />
            </div>
            <div id="responsibilities">
              <h3>Responsibilities</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: opening.responsibilities,
                }}
              />
            </div>
            <div id="about-you">
              <h3>About You</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: opening.aboutApplicant,
                }}
              />
            </div>

            <Button
              as="a"
              className={styles.Template_content_right_applyBtn}
              target="_blank"
              href={opening.link}
              variant={ButtonVariant.Primary}
            >
              Apply
            </Button>
          </div>
        </section>
      </div>
    </>
  );
};

export default CareersTemplate;
